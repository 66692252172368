@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/xkp6dmv.css");



* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: Usual, sans-serif;

}

body {
  font-family: Usual, sans-serif;
  color: white;
  background: #101010;
  user-select: none;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.usual {
  font-family: Usual, sans-serif;

}
